import styles from './NotFound.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

function NotFound() {
    return (
        <div id={cx('all')}>
            <div id={cx('center')}>
                <span id={cx('err-404')}>Error 404!</span>
                <br />
                <span className={cx('err-msg')}>The requested URL was not found on this server.</span>
            </div>
        </div>
    );
}

export default NotFound;
