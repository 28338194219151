import classNames from 'classnames/bind';
import { useState } from 'react';
import styles from './Login.module.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SETTINGS from '../../setting.json';
import gg_fb from '../../assets/images/logo_gg_fbs.png';
import Account from './index';

const axios = require('axios').default;

const cx = classNames.bind(styles);

function Register(props) {
    const [Lock1, setLock1] = useState(false);
    const [Lock2, setLock2] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const [invite, setInvite] = useState('');

    document.title = props.title;

    const checkLock = (data) => {
        if (data === 'password') {
            setLock1(!Lock1);
            return false;
        } else {
            setLock2(!Lock2);
            return false;
        }
    };

    const handleRegister = async (datas) => {
        let { username, password, repassword, invite } = datas;
        let pattern = /^[0-9]*\d$/;
        if (!username || !password || !repassword || !invite)
            return toast.warn('Vui lòng nhập đầy đủ thông tin', { theme: 'light' });
        if (!pattern.test(username)) return toast.warn('Tài khoản không đúng định dạng!', { theme: 'light' });
        if (password !== repassword) return toast.warn('Mật khẩu xác nhận không chính xác!', { theme: 'light' });

        axios
            .post(`${SETTINGS.BASE_URL}/api/webapi/register`, {
                username: username,
                password: password,
                invite: invite,
            })
            .then(function (response) {
                let status = response.data.status;
                if (status === 'ok') {
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1500);
                    localStorage.setItem('auth', response.data.auth);
                    return toast.success(response.data.message, { theme: 'light' });
                }
                return toast.error(response.data.message, { theme: 'light' });
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    return (
        <Account type="Đăng ký">
            <div className={cx('form-group')}>
                <input
                    onInput={(e) => setUsername(e.target.value)}
                    type="number"
                    placeholder="Số điện thoại"
                    autoComplete="off"
                    maxLength="128"
                    spellCheck="false"
                />
            </div>
            <div className={cx('form-group')}>
                <input
                    onInput={(e) => setPassword(e.target.value)}
                    type={Lock1 ? 'text' : 'password'}
                    placeholder="Mật khẩu"
                    autoComplete="new-password"
                    maxLength="128"
                />
                <FontAwesomeIcon
                    onClick={() => checkLock('password')}
                    className={cx('icon-lock')}
                    icon={Lock1 ? faLockOpen : faLock}
                />
            </div>
            <div className={cx('form-group')}>
                <input
                    onInput={(e) => setRepassword(e.target.value)}
                    type={Lock2 ? 'text' : 'password'}
                    placeholder="Nhập lại mật khẩu"
                    autoComplete="new-password"
                    maxLength="128"
                />
                <FontAwesomeIcon
                    onClick={() => checkLock('re_password')}
                    className={cx('icon-lock-open')}
                    icon={Lock2 ? faLockOpen : faLock}
                />
            </div>
            <div className={cx('form-group')}>
                <input
                    onInput={(e) => setInvite(e.target.value)}
                    type="tel"
                    placeholder="Nhập mã giới thiệu"
                    autoComplete="new-password"
                    maxLength="128"
                    spellCheck="false"
                />
            </div>
            <div
                className={cx('btn-submit')}
                style={{
                    pointerEvents: `${username.length < 9 || !password || !repassword ? 'none' : 'auto'}`,
                    opacity: `${username.length < 9 || !password || !repassword ? '0.7' : '1'}`,
                }}
            >
                <button type="button" onClick={() => handleRegister({ username, password, repassword, invite })}>
                    Đăng ký
                </button>
            </div>
            <div className={cx('login-others')}>
                <div className={cx('more')}>
                    <div className={cx('line')}></div>
                    <span className={cx('or')}>HOẶC</span>
                    <div className={cx('line')}></div>
                </div>
                <div className={cx('more-account')}>
                    <button className={cx('btn-facebook')}>
                        <div className={cx('content')}>
                            <div
                                className={cx('social-white-fb-blue-png')}
                                style={{ backgroundImage: `url(${gg_fb})` }}
                            ></div>
                        </div>
                        <div className="">Facebook</div>
                    </button>
                    <button className="btn-google">
                        <div className={cx('content')}>
                            <div
                                className={cx('social-white-google-png')}
                                style={{ backgroundImage: `url(${gg_fb})` }}
                            ></div>
                        </div>
                        <div className="">Google</div>
                    </button>
                </div>
            </div>

            <div className={cx('privacy')}>
                <div className={cx('content')}>
                    Bằng việc đăng kí, bạn đã đồng ý với Shopee về <br />
                    <a
                        href="https://shopee.vn/legaldoc/termsOfService/?__classic__=1"
                        className={cx('termsOfService')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Điều khoản dịch vụ
                    </a>{' '}
                    &amp;{' '}
                    <a
                        href="https://shopee.vn/legaldoc/privacy/?__classic__=1"
                        className={cx('privacy__js')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Chính sách bảo mật
                    </a>
                </div>
            </div>

            <div className={cx('register')}>
                <div className={cx('content')}>
                    <div className="">
                        Bạn đã có tài khoản?
                        <Link className={cx('btn-register')} to="/account/login">
                            Đăng nhập
                        </Link>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Account>
    );
}

export default Register;
