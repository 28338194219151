import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './Login.module.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import gg_fb from '../../assets/images/logo_gg_fbs.png';
import Account from './index';

import SETTINGS from '../../setting.json';
const axios = require('axios').default;

const cx = classNames.bind(styles);

function Login(props) {
    const [Lock, setLock] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        document.title = props.title;
    }, [props.title]);

    const checkLock = () => {
        setLock(!Lock);
    };
    const handleLogin = (datas) => {
        let { username, password } = datas;
        let pattern = /^[0-9]*\d$/;
        if (!username || !password) return toast.warn('Vui lòng nhập đầy đủ thông tin', { theme: 'light' });
        if (!pattern.test(username)) return toast.warn('Tài khoản không đúng định dạng!', { theme: 'light' });

        axios
            .post(`${SETTINGS.BASE_URL}/api/webapi/login`, {
                username: username,
                password: password,
            })
            .then(function (response) {
                let status = response.data.status;
                if (status === 'ok') {
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1500);
                    localStorage.setItem('auth', response.data.auth);
                    return toast.success(response.data.message, { theme: 'light' });
                }
                return toast.error(response.data.message, { theme: 'light' });
            })
            .catch(function (error) {
                console.log(error);
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    return (
        <Account type="Đăng nhập">
            <div className={cx('form-group')}>
                <input
                    onInput={(e) => {
                        setUsername(e.target.value);
                    }}
                    type="number"
                    placeholder="Số điện thoại"
                    autoComplete="off"
                    maxLength="128"
                    spellCheck="false"
                />
            </div>
            <div className={cx('form-group')}>
                <input
                    onInput={(e) => {
                        setPassword(e.target.value);
                    }}
                    type={Lock ? 'text' : 'password'}
                    placeholder="Mật khẩu"
                    autoComplete="new-password"
                    maxLength="128"
                />
                <FontAwesomeIcon onClick={checkLock} className={cx('icon-lock')} icon={Lock ? faLockOpen : faLock} />
            </div>
            <div className={cx('btn-submit')} style={{ opacity: `${username.length >= 9 && password ? '1' : '0.7'}` }}>
                <button
                    type="button"
                    style={{ pointerEvents: `${username.length < 9 || !password ? 'none' : 'auto'}` }}
                    className={cx('block-events')}
                    onClick={() => handleLogin({ username, password })}
                >
                    Đăng nhập
                </button>
            </div>
            <div className={cx('forgot-password')}>
                <a className={cx('')} href="/support">
                    Quên mật khẩu ?
                </a>
            </div>
            <div className={cx('login-others')}>
                <div className={cx('more')}>
                    <div className={cx('line')}></div>
                    <span className={cx('or')}>HOẶC</span>
                    <div className={cx('line')}></div>
                </div>
                <div className={cx('more-account')}>
                    <button className={cx('btn-facebook')}>
                        <div className={cx('content')}>
                            <div
                                className={cx('social-white-fb-blue-png')}
                                style={{ backgroundImage: `url(${gg_fb})` }}
                            ></div>
                        </div>
                        <div className="">Facebook</div>
                    </button>
                    <button className="btn-google">
                        <div className={cx('content')}>
                            <div
                                className={cx('social-white-google-png')}
                                style={{ backgroundImage: `url(${gg_fb})` }}
                            ></div>
                        </div>
                        <div className="">Google</div>
                    </button>
                </div>
            </div>
            <div className={cx('register')}>
                <div className={cx('content')}>
                    <div className="">
                        Bạn mới biết đến Shopee?
                        <Link className={cx('btn-register')} to="/account/register">
                            Đăng ký
                        </Link>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Account>
    );
}

export default Login;
