// import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Layout/components/Header';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function ComfirmOrder(state) {
    const { id } = useParams();
    const id_mission = useRef(id);
    let [mission, setMission] = useState();
    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/mission/confirm`, {
                params: {
                    id_mission: id_mission.current,
                },
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setMission(data.data);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    let navigate = useNavigate();

    function confirmMission() {
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/confirm/id`,
                { id_mission: id_mission.current },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    if (data.data.type === 2) return toast.warn('Nhiệm vụ không tồn tại !', { theme: 'light' });
                    if (data.data.type === 3) return toast.warn('Bạn đã làm nhiệm vụ này rồi !', { theme: 'light' });
                    if (data.data.type === 4) return toast.warn('Cấp bậc của bạn không đủ !', { theme: 'light' });
                    if (data.data.type === 0)
                        return toast.warn('Số dư không đủ, vui lòng nạp thêm vốn !', { theme: 'light' });
                    if (data.data.type === 1) {
                        setTimeout(() => {
                            navigate('/order/index');
                        }, 1200);
                        return toast.success('Xác nhận đơn hàng thành công !', { theme: 'light' });
                    }
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }

    if (!Array.isArray(mission) || mission.length <= 0) return false;

    return (
        <>
            <Header
                color="#fff"
                colorTxt="#f89b34"
                arrowLeft="#27ae60"
                border={true}
                fixed={true}
                title="Xác nhận đơn hàng"
                param={`/order/mission/${mission[0].level_mission}`}
            />
            <div className="px-[12px] pb-[36px] pt-[90px] mx-auto">
                <div className="text-center mt-[20px]">
                    <div className="text-[#0dc253] font-medium text-[20px]">Đã ra lệnh thành công</div>
                    <img className="w-[108px] h-[108px] mx-auto my-[28px] rounded-2xl" src={mission[0].image} alt="" />
                </div>
                <div>
                    <p className="title font-bold text-[16px]">{mission[0].name_mission}</p>
                    <div className="flex justify-left items-center pt-[5px] pb-[22px]">
                        <img className="w-[18px] h-[18px]" src="https://i.imgur.com/FdFZbYL.png" alt="" />
                        <p className="ml-[10px] text-xl text-[#a6c4c3]">A parts de: Shopee</p>
                    </div>
                    <div>
                        <div className="flex justify-between">
                            <p className="text-[#0dc253] font-medium text-[16px]">Giá trị của đơn hàng</p>
                            <p className="text-[#ffa900] font-bold text-[16px]"> {formatMoney(mission[0].price)} ₫</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-[#0dc253] font-medium text-[16px]">Lợi nhuận</p>
                            <p className="text-[#ffa900] font-bold text-[16px]"> {formatMoney(mission[0].receive)} ₫</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-[#0dc253] font-medium text-[16px]">Số tiền nhận lại</p>
                            <p className="text-[#ffa900] font-bold text-[16px]">
                                {' '}
                                {formatMoney(mission[0].price + mission[0].receive)} ₫
                            </p>
                        </div>
                    </div>
                    <div className="mt-[20px]">
                        <div
                            onClick={() => confirmMission()}
                            className="p-[10px] rounded-full text-center text-white"
                            style={{ background: 'linear-gradient(0deg,#f37a60,#f04d2a)' }}
                        >
                            Gửi đơn hàng
                        </div>

                        <div className="underline text-center my-[20px] text-[#7b9695] text-[18px]">
                            <Link to={`/order/mission/${mission[0].level_mission}`}>Bỏ qua</Link>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="mb-[5px] text-2xl text-note text-[#7b9695]">Ghi chú</p>
                    <div className="pl-[10px]">
                        <p className="mb-[5px] text-[#7b9695] text-xl">
                            - Khi bạn nộp đơn đặt hàng , số tiền mua hàng sẽ trừ từ số dư trong tài khoản của bạn ngay
                            khi ra lệnh thành công.
                        </p>
                        <p className="mb-[5px] text-[#7b9695] text-xl">
                            - Sau khi gửi xong lệnh giao dịch, hệ thống sẽ giúp bạn hoàn thành giao dịch vào thời gian
                            sớm nhất.Thời gian chính xác phụ thuộc vào sự xát nhận của chúng tôi.
                        </p>
                        <p className="mb-[5px] text-[#7b9695] text-xl">
                            - Nếu giao dịch thất bại, lệnh giao dịch sẽ hủy tự động và vốn đặt hàng của bạn sẽ được hoàn
                            trả lại.
                        </p>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export default ComfirmOrder;
